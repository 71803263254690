.hero {
  width: 100%;
  height: 70vh;
  margin-top: -76px;
}

.logoOverlay {
  padding-top: 10%;
  max-width: 50%;
}

/* tablets */
@media screen and (min-width: 425px) and (max-width: 767px) {
  .logoOverlay {
    padding-top: 10%;
    max-width: 100%;
  }
}
/* phone screens */
@media screen and (min-width: 320px) and (max-width: 424px) {
  .logoOverlay {
    padding-top: 30%;
    max-width: 100%;
  }
  .tagline {
    padding-left: 8%;
  }
}
