/* #070504 #88999F #BD3822 #FBFBF8 # */

.pageView {
  /* background-color: #fbfbf8; */
  background-image: url("./assets/images/bkg.png");
  background-repeat: no-repeat;
  object-fit: cover;
  font-family: "Open Sans", san-serif;
  font-weight: lighter;
  letter-spacing: 1px;
  color: 070504;
}

h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  /* filter: drop-shadow(0px 0px 5px #88999f); */
}

a {
  font-family: "Montserrat", sans-serif;
  /* grows all links on hover */
  transition: all 0.2s ease-in-out;
}
a:hover {
  transform: scale(1.1);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(5px 5px 15px #88999f);
}

.section-container {
  /* padding: 75px 0px; */
  margin-top: 20px;
}
span.anchor {
  margin-top: -76px; /* height of nav */
  display: block;
  height: 76px; /* height of nav */
  visibility: hidden;
  position: relative;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .section-container {
    padding-bottom: 10px;
  }
  a {
    transition: unset;
  }
  a:hover {
    transform: unset;
  }
}
