.portfolioPage {
  background-color: #e9dbd7;
  font-family: "Spartan", san-serif;
  color: black;
}
.portfolioFolders {
  padding-top: 20px;
}
/*   
  h1,
  h2,
  h3 {
    filter: drop-shadow(0px 0px 5px #f6f0e4);
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(5px 5px 15px #b5b5ad);
  }
   */
