.notFound {
  padding-top: 15%;
  height: 100vh;
  width: 100vw;
}
.imageCenter {
  display: flex-row;
  justify-content: center;
  align-items: center;
}
/* 
@media screen and (min-width: 320px) and (max-width: 767px) {
  .notFound {
    padding-top: 25%;
  }
} */
