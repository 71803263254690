.navBackgroundTransparent {
  background-color: transparent;
  padding: 8;
}

.navBackgroundColor {
  background-color: #e8e8e1;
  filter: drop-shadow(-5px -5px 5px #070504);
  padding: 8;
}

.navbar-brand {
  margin-left: 10px;
}

.show,
.collapsing {
  background-color: #e8e8e1;
}
